export const Sounds = [
  { name: 'music-home', opts: { volume: 0.4 } },
  { name: 'music-enigme-speed-up', opts: { volume: 0.6 } },
  { name: 'music-enigme', opts: { volume: 0.5, isLoop: true } },
  { name: 'labo_ambiance', opts: { volume: 0.2, isLoop: true } },
  { name: 'simlich-rire', opts: { volume: 1 } },
  { name: 'select-2', opts: { volume: 0.2 } },
  { name: 'select-3', opts: { volume: 0.4 } },
  { name: 'validation', opts: { volume: 0.3 } },
  { name: 'validate', opts: { volume: 0.4 } },
  { name: 'phone', opts: { volume: 0.2, isLoop: true, timeBeforeLoop: 1000 } },
  { name: 'calling', opts: { volume: 0.2 } },
  { name: 'pad-1', opts: { volume: 0.5 } },
  { name: 'tick-2', opts: { volume: 0.3 } },
  { name: 'swoosh-1', opts: { volume: 0.2 } },
  { name: 'swoosh-2', opts: { volume: 0.2 } },
  { name: 'swoosh-3', opts: { volume: 0.2 } },
  { name: 'swoosh-enter', opts: { volume: 0.5 } },
  { name: 'success', opts: { volume: 0.5 } },
  { name: 'failure', opts: { volume: 0.7 } },
  { name: 'page-3', opts: { volume: 0.8 } },
  { name: 'page-4', opts: { volume: 0.8 } },
  { name: 'page-5', opts: { volume: 0.8 } },
  { name: 'page-6', opts: { volume: 0.8 } },
  { name: 'page-7', opts: { volume: 0.8 } },
  { name: 'page-8', opts: { volume: 0.8 } },
  { name: 'page-9', opts: { volume: 0.8 } },
  { name: 'back', opts: { volume: 0.4 } },
  { name: 'message-1', opts: { volume: 0.3 } },
  { name: 'notification', opts: { volume: 0.2 } }
]
